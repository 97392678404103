import React from 'react'
import NoAvailableData from '../NoAvailableData';
import LoadingCircle from './LoadingCircle';
import { List,  ListItemText, ListItem, Card, CardHeader } from '@mui/material';
import Divider from '@mui/material/Divider';
import { formatBytes } from "../../utils/formatNumbers";

import { GetUsageAndStorageSortedUseCase } from "../../services/file_service/use_cases/GetUsageAndStorageSortedUseCase";

var getUsageAndStorageSortedUseCase = new GetUsageAndStorageSortedUseCase();

const IsUndefOrNull = (obj) =>
{
    if (obj === null)
    {
        return true;
    }

    return obj === undefined;
}

const getNameForView = (name, isBigScreen) =>
{
    if (isBigScreen === false && name.length > 10)
    {
        return name.slice(0,10) + '...'
    }

    return name;
}

const ListMostViewsFramework = ({sortedListMostViewd, sortedListMostViewdLength, isBigScreen}) =>
{
    return (<List >
                {sortedListMostViewd.map((vidFile, vidListIndex) => {
                            return <div key={ "masterChild" +vidFile.Id}>
                                <ListItem
                                    style={{paddingLeft:10, paddingRight:10, margin:0}}
                                    dense
                                    disableGutters
                                    disablePadding
                                    secondaryAction={
                                        <ListItemText 
                                        style={{paddingRight:15}}
                                        primary={formatBytes(sortedListMostViewd[vidListIndex].TotalUsage)} sx={{ opacity: 1 }} />                      
                                    }
                                    >                           
                                        {/*<ListItemText primary={getNameForView(vidFile.FileName, isBigScreen)} secondary={"Username"}  sx={{ opacity: 1 }} />*/}
                                        <ListItemText primary={getNameForView(vidFile.FileName, isBigScreen)} sx={{ opacity: 1 }} />
                                </ListItem>

                                {vidListIndex === (sortedListMostViewdLength - 1) ? null :  <Divider style={{color:"#FAFAFA"}}/>}
                            </div>;     
                    })}
            </List>);
}

/*
const TryJoinUsageAndStorage = (videoUsage, videoData) =>
{
    videoUsage.combainVideoStorageInformation(videoData);
    if (videoUsage.isComninedWithStorageInfo === false)
    {
        return [[], 0];
    }

    var sortedListMostViewd = videoUsage.getSortedVideoUsageByMostViews();
    var sortedListMostViewdLength = sortedListMostViewd.length;
    return [sortedListMostViewd, sortedListMostViewdLength];
}

const GetsortedListMostViewdSecindVersion = (videoUsage, videoData) =>
{
    var sortedListMostViewd = videoUsage.getSortedVideoUsageByMostViews();
    var sortedListMostViewdLength = sortedListMostViewd.length;
    if (sortedListMostViewdLength === 0)
    {
        return [sortedListMostViewd, sortedListMostViewdLength];
    }

    // Check and try to fix
    if (videoUsage.isComninedWithStorageInfo === false)
    {
        videoUsage.combainVideoStorageInformation(videoData);
        [sortedListMostViewd, sortedListMostViewdLength] = TryJoinUsageAndStorage(videoUsage, videoData);
    }

    // If we coudn't join them
    if (videoUsage.isComninedWithStorageInfo === false)
    {
        return [[], 0];
    }

    // Return only the joined
    const finelRes = []
    sortedListMostViewd.forEach(element => {
        if (element.fileName !== null && element.fileName  !== undefined &&
            element.TotalUsage !== null && element.TotalUsage  !== undefined)
        {
            finelRes.push(element);
        }
        
    });

    return [finelRes, finelRes.length];
}
*/
const MostViewdList = ({videoUsageStorage, isLoading, isBigScreen}) => {
    let view = <NoAvailableData />;
    if (IsUndefOrNull(videoUsageStorage) === false) { //videoData !== null) {
        //videoData = JSON.parse(videoData.data);
        var sortedListMostViewd = getUsageAndStorageSortedUseCase.GetSortedByUsage(videoUsageStorage);  // by usage and not by view yet
        var sortedListMostViewdLength = IsUndefOrNull(sortedListMostViewd) ? 0 : sortedListMostViewd.length;
        if (sortedListMostViewdLength > 0)
        {
            sortedListMostViewd = sortedListMostViewd.slice(0, 6); // by usage and not by view yet
            sortedListMostViewdLength = sortedListMostViewd.length;
        }

        //const [sortedListMostViewd, sortedListMostViewdLength] = GetsortedListMostViewd(videoData, videoUsage);
        if (sortedListMostViewdLength !== 0)
        {
            // If we dont have in the videoUsage videoData information - update it
            view = <ListMostViewsFramework 
                        sortedListMostViewd={sortedListMostViewd} 
                        sortedListMostViewdLength={sortedListMostViewdLength}
                        isBigScreen={isBigScreen}
                    />;
        }
    }

    return (
        <Card >
            <CardHeader title="Most Viewed" style={{ color:"#757575"}}/>
            {isLoading === true ? <LoadingCircle/> : view}
        </Card>
    )
}

export default MostViewdList;



