import React from 'react'
import LoadingCircle from './LoadingCircle';
import NoAvailableData from "../NoAvailableData";
import CostunmPie from "../Charts/PieChart";
import { fCurrency } from "../../utils/formatNumbers";
import { styled } from "@mui/material/styles"
import {
  Card,
  CardHeader,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square"
import { GetUsageAndStorageCostUseCase } from  "../../services/file_service/use_cases/GetUsageAndStorageCostUseCase";



const CHART_HEIGHT = 372
const LEGEND_HEIGHT = 72
const gigaByte = 1000000000
const taxPrecent = 0.17

// Global functionality helper
const getUsageAndStorageCostUseCase = new GetUsageAndStorageCostUseCase();


const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}))


const GetPlanPrice = userInformation => {
    return userInformation.planPrice
}

/*
const VideoUsageObjectToList = videoUsage => {
    const currentMonth = Object.keys(videoUsage["monthlyUsage"])[0]
    return videoUsage["monthlyUsage"][currentMonth]["videoUsage"]
  }*/
  
  const GetPlanPriceFromBendwidthGigaUsage = userInformation => {
    return userInformation.pricePerGigaBendwidth
  }
  
  const GetPlanFreeBendwidthGigaUsage = userInformation => {
    return userInformation.freeGigaUsage
  }
  
  const GetBwPrice = (userInformation, videoUsageStorage) => {
    const bwBytes = getUsageAndStorageCostUseCase.GetTotalUsage(videoUsageStorage);
    const gigaCoast = GetPlanPriceFromBendwidthGigaUsage(userInformation)
    const freeGigaFromPlan = GetPlanFreeBendwidthGigaUsage(userInformation)  
    const gigaByteBw = (bwBytes / gigaByte) - freeGigaFromPlan
    if (gigaByteBw < 0) {
      return 0
    }
  
    return gigaByteBw * gigaCoast
  }

  

const GetPlanPriceFromStorageGigaUsage = userInformation => {
  return userInformation.pricePerGigaStorage
}

const GetPlanFreeStorageGigaUsage = userInformation => {
  return userInformation.freeGigaStorage
}

const GetStoragePrice = (userInformation, videoUsageStorage) => {
  const gigaCoast = GetPlanPriceFromStorageGigaUsage(userInformation)
  const freeGigaFromPlan = GetPlanFreeStorageGigaUsage(userInformation)
  const storageBytes = getUsageAndStorageCostUseCase.GetTotalStorage(videoUsageStorage);

  var gigaByteStorage = (storageBytes / gigaByte) - freeGigaFromPlan
  if (gigaByteStorage < 0) {
    return 0
  }

  return gigaByteStorage * gigaCoast
}

const GetChartData = (userInformation, videoUsageStorage) => {
    if (userInformation === null || videoUsageStorage === null || videoUsageStorage === undefined)
    {
      return null;
    }

    return [
      {
        id: "plan",
        label: "Plan",
        value: GetPlanPrice(userInformation),
        color: "#BBDEFB",
      },
      {
        id: "bandwidth",
        label: "Bandwidth",
        value: GetBwPrice(userInformation, videoUsageStorage),
        color: "#42A5F5",
      },
      {
        id: "storage",
        label: "Storage",
        value: GetStoragePrice(userInformation, videoUsageStorage),
        color: "#1976D2",
      },
    ]
}

const GetTaxData = chartData => {
    var tax = 0
    chartData.forEach(element => {
      tax += element.value
    })
  
    return [
      {
        id: "tax",
        label: "Tax",
        value: tax * taxPrecent,
      },
    ]
  }

const GetView = (chartData) => {
    var totalPrice = chartData.reduce(
        (accumulator, current) => accumulator + current.value,
        0
      )
      totalPrice = totalPrice + GetTaxData(chartData)[0].value
    
      return (
        <>
          <CostunmPie
            data={chartData}
            margines={{ top: 30, bottom: 30, left: 5, right: 5 }}
          />
    
          <List
            style={{
              position: "absolute",
              margin: "auto",
              marginBottom: 15,
              bottom: 0,
              left: 5,
              right: 5,
              paddingLeft: 5,
              paddingRight: 5,
              maxWidth: 276,
            }}
            disablePadding
          >
            {chartData.map(buttonText => {
              return (
                <ListItem
                  key={buttonText.label}
                  dense
                  disableGutters
                  disablePadding
                  secondaryAction={
                    <ListItemText
                      style={{ paddingRight: 15 }}
                      primary={fCurrency(buttonText.value.toFixed(2))}
                      sx={{ opacity: 1 }}
                    />
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                      color: buttonText.color,
                    }}
                  >
                    <SquareIcon />
                  </ListItemIcon>
    
                  <ListItemText primary={buttonText.label} sx={{ opacity: 1 }} />
                </ListItem>
              )
            })}
    
            {GetTaxData(chartData).map(buttonText => {
              return (
                <ListItem
                  key={buttonText.label}
                  dense
                  disableGutters
                  disablePadding
                  secondaryAction={
                    <ListItemText
                      style={{ paddingRight: 15 }}
                      primary={fCurrency(buttonText.value.toFixed(2))}
                      sx={{ opacity: 1 }}
                    />
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                      color: "transparent",
                    }}
                  >
                    <SquareIcon />
                  </ListItemIcon>
    
                  <ListItemText primary={buttonText.label} sx={{ opacity: 1 }} />
                </ListItem>
              )
            })}
    
            <ListItem
              style={{ backgroundColor: "#FFFFFF", borderRadius: 10 }}
              dense
              disableGutters
              disablePadding
              secondaryAction={
                <ListItemText
                  style={{ paddingRight: 15 }}
                  disableTypography
                  primary={
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      style={{ color: "black" }}
                    >
                      {fCurrency(totalPrice.toFixed(2))}
                    </Typography>
                  }
                />
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                  color: "transparent",
                }}
              >
                <SquareIcon />
              </ListItemIcon>
    
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    style={{ color: "black" }}
                  >
                    {"Total:"}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </>
      )
}

const ParseIfNotNull = (obj) =>
{
  if (obj !== null)
  {
    return JSON.parse(obj.data);
  }

  return obj;
}

// 3 options:
//  Loading state, have data, don't have data
const MonthToDateSpending = ({userInformation, isLoading, videoUsageStorage}) => {

    var data = null;
    var view = null;

    if (isLoading === false)
    {
        userInformation = ParseIfNotNull(userInformation);
        data = GetChartData(userInformation, videoUsageStorage);
        view = data === null ? <NoAvailableData /> : GetView(data);
    }

  return (
    <Card
      style={{
        height: 671,
        position: "relative",
        backgroundColor: "#E3F2FD",
      }}
    >
      <CardHeader
        title="Monthly Summary"
        style={{ textAlign: "center", color: "#1976D2" }}
      />
      <ChartWrapperStyle dir="ltr">
        {isLoading === true ? <LoadingCircle/> : view}
      </ChartWrapperStyle>
    </Card>
  )
}

export default MonthToDateSpending;
