const IsUndefOrNull = (obj) =>
{
    return obj === null || obj === undefined;
}

// data = the current data object in state (videoUsageStorage)
export class GetUsageAndStorageCostUseCase
{
    GetTotalPrice(data)
    {
        if (IsUndefOrNull(data))
        {
            return null;
        }
        return data.TotalPrice;
    }

    GetTotalUsage(data)
    {
        if (IsUndefOrNull(data))
        {
            return null;
        }
        return data.TotalUsage;
    }

    GetTotalStorage(data)
    {
        if (IsUndefOrNull(data))
        {
            return null;
        }
        return data.TotalStorage;
    }
}