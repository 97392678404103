import React from 'react'
import {  CircularProgress, Stack } from '@mui/material';

const LoadingCircle = () => {
  return (
    <Stack sx={{ color: 'grey.500', height:80 }} spacing={2} direction="row">
        <CircularProgress color="inherit" style={{margin: "auto"}}/>
    </Stack>
  )
}

export default LoadingCircle
