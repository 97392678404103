import { VideoStorageAndUsage } from "../services/file_service/entities/VideoStorageAndUsage";
import { AddRemoveAndChangeVideoStatusUseCase } from "../services/file_service/use_cases/AddRemoveAndChangeVideoStatusUseCase";

const RANDOM_STRING_SIZE = 10;

const generateRandomString = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < RANDOM_STRING_SIZE; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

export const OnFileUploadHandler = (isUserInformationLoading, files, videoUsageStorage, dispatch, cbToSetToNone=null) =>
  {
    //console.log("[OnFileUploadHandler] got called");
    // make sure we run the login only if we finish loading
    if (isUserInformationLoading === true)
    {
      setTimeout(OnFileUploadHandler, 100, files);
      return;
    }

    if (files === null)
    {
      //console.log("[onFileUploadHandler] send size check:", videoUsageStorage.VideoList.length);
      return;
    }

    // This will handle single file for us
    const newFiles = [];
    files.forEach(file => newFiles.push(new VideoStorageAndUsage(generateRandomString(), 0, 0, 0, file.file.name, "Processing", 0, file.file.size, Date.now(), Date.now(), null)));
    const addRemAndChange = new AddRemoveAndChangeVideoStatusUseCase(null);
    //console.log("[onFileUploadHandler] send size:", videoUsageStorage.VideoList.length);
    addRemAndChange.AddVideo(newFiles, videoUsageStorage, dispatch);
    //console.log("[onFileUploadHandler] After add video");

    // Finish our job
    if (cbToSetToNone !== null)
    {
      //console.log("[onFileUploadHandler] update state to null");
      cbToSetToNone(null);
    }

    //console.log("[onFileUploadHandler] finish");
  }