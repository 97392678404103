import isNullOrUndefined from "./isNullOrUndefined";
import getMinuteDifference from "./timeUtilities/getMinuteDifference";


const MAX_TTL_FOR_OBJ_MINUTES = 60;

/*const EXCPECTED_EXAMPLE_OBJECT = { 
    date: null,
    time: null
}*/

const isExpired = (obj) =>
{
    const minuteDifferent = getMinuteDifference(Date.now(), obj.time);
    if (minuteDifferent > MAX_TTL_FOR_OBJ_MINUTES)
    {
        return true;
    }

    return false;
}

const isObjectValid = (obj) => {
  if (isNullOrUndefined(obj) === true)
  {
      return false;
  }

  if (isExpired(obj) === true)
  {
      return false;
  }
  return true;
}

export default isObjectValid
