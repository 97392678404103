import React, { useEffect, useState, useCallback } from 'react'
import { navigate } from "gatsby"
import { useSelector, useDispatch } from 'react-redux'
import { ClearToken,
  SetUserInformation,
  IsTokenExpired} from "../store/store";

import { Helmet } from 'react-helmet';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Stack from '@mui/material/Stack';

import Layout from '../components/layout';
import MonthToDateSpending from '../components/dashboardComponents/MonthToDateSpending';
import MostViewdList from '../components/dashboardComponents/MostViewdList';
import FileUploadButton from '../components/FileUploadButton';
import {gFileInUploadProcess} from '../components/FileUploadButton';
import {DoWeInTheMiddleOfFileUpload} from '../components/FileUploadButton';

import LastVideoUploads from "../components/dashboardComponents/LastVideoUploads";
import isObjectValid from "../utils/isObjectValid";
import axios from "../projectAxios";

import {IFetcherFramework} from "../services/file_service/entities/IFetcherFramework";
import {videoStorageAndUSageFramework} from "../services/file_service/frameworks/videoStorageAndUSageFramework";
import { IDataManipulatorFramework } from "../services/file_service/entities/IDataManipulatorFramework";
import { AddRemoveAndChangeVideoStatusUseCase } from "../services/file_service/use_cases/AddRemoveAndChangeVideoStatusUseCase";

import { OnFileUploadHandler } from "../PageUtilities/OnFileUpload";
import { IsServerMetricsCapable } from "../utils/capabilities";

const isOneOfLoadingActive = (isVideoDataLoading, isVideoUsageLoading, isUserInformationLoading) =>
{
  return isVideoDataLoading === true || isVideoUsageLoading  === true || isUserInformationLoading === true;
}

const Fetcher = (location, dispatcher, token, saveStateDispatcher, cbForLoadingStateChange) => 
{
    // here is the exius req.
    axios.get(
      location,
      {
        headers: {
          Authenticate: token,
        },
      },
      {}
    )
    .then(resp => {
      dispatcher(saveStateDispatcher(resp.data));
      cbForLoadingStateChange(false);
      return;
    })
    .catch(err => {
      if ( err.response && (err.response.status === 403 || err.response.status === 401)) {         
       // only if not process at the moment sometihng
       if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
       {
         console.log("Do nothing");
         return null;
       }

        // Token probebly expired
        dispatcher(ClearToken());
        navigate("/login");
        return null;
      }

      if ( err.response && (err.response.status === 404)) {          
        // Token probebly expired
        cbForLoadingStateChange(false);
        return null;
      }
    });
}

const FetchIfNotValid = (obj, fetchFrom, cbForLoadingStateChange, dispatcher, saveStateDispatcher, token) =>
{
  if (isObjectValid(obj) === true)
  {
    // not loading anymore
    cbForLoadingStateChange(false);

    return;
  }

  // use axios for fatching and update the new value
  Fetcher(fetchFrom, dispatcher, token, saveStateDispatcher, cbForLoadingStateChange);
}

const getFirstName = (userInformation) => {
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  return JSON.parse(userInformation.data).firstName;
}

const getEncryptionStatus = (userInformation) =>
{
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  const encStatus = JSON.parse(userInformation.data).encryption;
  return encStatus === true || encStatus === "true" || encStatus === "True";
}

const getIsServerMetricsCapable = (userInformation) => {
  if (isObjectValid(userInformation) !== true)
  {
    return null;
  }

  return IsServerMetricsCapable(JSON.parse(userInformation.data).capabilities);
}


var shouldRerenderContant = false;
const Dashboard = () => {
  const dispatch = useDispatch();
  const isBigScreen = useMediaQuery('(min-width:600px)');
  const { isLoggedIn, token, userInformation, videoUsageStorage } = useSelector((state) => {
    return {
      isLoggedIn: state?.isLoggedIn || false,
      token: state?.token || null,
      userInformation: state?.userInformation || null,
      videoUsageStorage: state?.videoUsageStorage || null,
    };
  });

  const [fileNeedsToBeUploaded, setFileNeedsToBeUploaded] = useState(null);
  const [isVideoUsageStorage, setisVideoUsageStorage] = useState(true);
  const [isUserInformationLoading, setIsUserInformationLoading] = useState(true);


  useEffect(() => {
    if (isLoggedIn === false || token === null || token === undefined)
    {
       // only if not process at the moment sometihng
        if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
        {
          console.log("Do nothing");
          return;
        }

      navigate("/login");
      return;
    }

    const helper = new videoStorageAndUSageFramework(token);
    const usageAndStorageFramework = new IFetcherFramework(helper);

    // this will update the state on finish if there is what to update
    if (videoUsageStorage === null || videoUsageStorage === undefined)
    {
      usageAndStorageFramework.FetchFromServer(dispatch).then( resp => { setisVideoUsageStorage(false); });
    }
    else
    {
      setisVideoUsageStorage(false);
    }
    
    const dataManipulator = new IDataManipulatorFramework(helper);
    new AddRemoveAndChangeVideoStatusUseCase(dataManipulator); // This will save itself

    // Here we take care the actual upload of staff
    if (shouldRerenderContant === true && fileNeedsToBeUploaded !== null)
    {
      shouldRerenderContant = false;
      
      // When this finish to run - he will update our state to null
      OnFileUploadHandler(isUserInformationLoading, fileNeedsToBeUploaded, videoUsageStorage, dispatch, setFileNeedsToBeUploaded);
    }

    FetchIfNotValid(userInformation, "/UserInformation", setIsUserInformationLoading,  dispatch, SetUserInformation, token);
  }, [isLoggedIn,  dispatch,  token, videoUsageStorage, userInformation, fileNeedsToBeUploaded]);

  useEffect(() => {
    const interval = setInterval(() => {
       // only if not process at the moment sometihng
        if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
        {
          console.log("Do nothing");
          return;
        }
        
        dispatch(IsTokenExpired()); // Dispatch the action to update the state
    }, 60000); // Check every 1 minute (60,000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [dispatch]);


  // Use this effect for make sure during upload the user can't switch screen
  /*useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Check if we during any upload
      if (false === DoWeInTheMiddleOfFileUpload())
      {
        return;
      }

      // Cancel the event
      e.preventDefault();

      // Chrome requires returnValue to be set
      e.returnValue = '';

      // Prompt the user with a confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave?';
      e.returnValue = confirmationMessage; // For legacy browsers

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up by removing the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);*/


  const onNotAuthorizedFileUpload = () =>
  {
       // only if not process at the moment sometihng
       if (gFileInUploadProcess !== null && gFileInUploadProcess !== undefined && gFileInUploadProcess > 0)
       {
         console.log("Do nothing");
         return;
       }
       
    dispatch(ClearToken());
    navigate("/login");
  }

  const LocalSetFileNeedsToBeUploaded =  useCallback((files) =>
  {
    shouldRerenderContant = true;
    setFileNeedsToBeUploaded(files);
  }, []);

  const LocalOnNotAuthorizedFileUpload = useCallback(() => {
    onNotAuthorizedFileUpload();
  }, []);

  const leftSide = (<Stack spacing={2} >
    <MonthToDateSpending  
      userInformation={userInformation}
      isLoading={isOneOfLoadingActive(false, isVideoUsageStorage, isUserInformationLoading)}
      videoUsageStorage={videoUsageStorage}
    />
    <FileUploadButton
      token={token}
      onLoadHandler={LocalSetFileNeedsToBeUploaded} 
      onNotAuthorized={LocalOnNotAuthorizedFileUpload} 
      shouldBeEncrypted={getEncryptionStatus(userInformation)}/>
  </Stack>);

const roghtSide = (<Stack spacing={2}>
  <LastVideoUploads 
    videoUsageStorage={videoUsageStorage}
    isLoading={isVideoUsageStorage}
    isBigScreen={isBigScreen}
  />
  <MostViewdList
    videoUsageStorage={videoUsageStorage}
    isLoading={isOneOfLoadingActive(isVideoUsageStorage, isUserInformationLoading, false)}
    isBigScreen={isBigScreen}
  />
</Stack>);

  const smallScreenLayout = (<Stack spacing={2} style={{maxWidth: 427}}>
    {leftSide}
    {roghtSide}
  </Stack>);

  const bigScreenLayout = (<Grid container spacing={2}>
    <Grid xs="auto"  >
      {leftSide}
    </Grid>

    <Grid xs="auto" sm>
      {roghtSide}
    </Grid>      
  </Grid>);

  const layout = (<Layout userName={getFirstName(userInformation)} canReadServerMetrics={getIsServerMetricsCapable(userInformation)}>
    { isBigScreen ? bigScreenLayout : smallScreenLayout }
    
  </Layout>);

  return (
    <>
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
        
        {isLoggedIn === true ? layout : null}
        
      </>
  )
}

export default Dashboard
