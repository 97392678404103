import React from 'react'
//import { navigate } from "gatsby"
import {SafeNavigate} from '../../components/FileUploadButton';

import NoAvailableData from '../NoAvailableData';
import LoadingCircle from './LoadingCircle';

import { Card, CardHeader, List,  ListItemText, ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';

import { GetUsageAndStorageSortedUseCase } from "../../services/file_service/use_cases/GetUsageAndStorageSortedUseCase";

var getUsageAndStorageSortedUseCase = new GetUsageAndStorageSortedUseCase();


const getNameForView = (name, isBigScreen) =>
{
    if (isBigScreen === false && name.length > 10)
    {
        return name.slice(0,10) + '...'
    }

    return name;
}

const IsUndefOrNull = (obj) =>
{
    if (obj === null)
    {
        return true;
    }

    return obj === undefined;
}

const getLastFiveUploads = (videoUsageStorage) =>
{
    if (IsUndefOrNull(videoUsageStorage))
    {
        return [];
    }

    const sortedList = getUsageAndStorageSortedUseCase.GetSortedByUploadDate(videoUsageStorage);
    return sortedList.slice(0, 5);
}

const getLastViewdTble = (videoUsageStorage, isBigScreen) =>
{
    const sortedList = getLastFiveUploads(videoUsageStorage);
    const sortedListLength = sortedList.length;
    return (<List >
        {sortedList.map((vidFile, vidListIndex) => {
                    return <div key={ "masterChild" +vidFile.Id + vidFile.UploadDate}>
                        <ListItem
                            style={{paddingLeft:10, paddingRight:10, margin:0}}
                            dense
                            disableGutters
                            disablePadding
                            secondaryAction={
                                <ListItemText 
                                style={{paddingRight:15}}
                                primary={new Date(vidFile.UploadDate).toDateString() } sx={{ opacity: 1 }} />                      
                            }
                            >                           
                                {/*<ListItemText primary={getNameForView(vidFile.FileName, isBigScreen)} secondary={"Username"}  sx={{ opacity: 1 }} />*/}
                                <ListItemText primary={getNameForView(vidFile.FileName, isBigScreen)}  sx={{ opacity: 1 }} />
                        </ListItem>
                        {vidListIndex === (sortedListLength - 1) ? null :  <Divider style={{color:"#FAFAFA"}}/>}
                        </div>;     
            })}
    </List>);
}

const LastVideoUploads = ({videoUsageStorage, isLoading, isBigScreen}) => {

    let view = <NoAvailableData />;
    if (IsUndefOrNull(videoUsageStorage) === false) {
        view = getLastViewdTble(videoUsageStorage, isBigScreen);
    }

    return (
        <Card onClick = {() => {SafeNavigate("/content");}} style={{cursor: "pointer"}}>
            <CardHeader title="Recent Uploads" style={{color:"#757575"}}/>
            {isLoading === true ? <LoadingCircle/> : view}
        </Card>
    )
}

export default LastVideoUploads;
